<template>
  <div
    class="popup_wrap"
    style="width:70%;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.NEWB010P110.001') }}</h1> <!-- DEM/DET 계산기 -->
      <div class="content_box mt10">
        <h2 class="content_title">{{ $t('msg.NEWB010P110.002') }}</h2> <!-- 반입일(화주입력) -->
        <table class="tbl_col mt10">
          <colgroup>
            <col style="width:6%">
            <col style="width:6%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:9.5%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="*">
          </colgroup>
          <thead>
            <tr>
              <th scope="colgroup" colspan="6">{{ $t('msg.NEWB010P110.003') }}</th> <!-- Container -->
              <th scope="colgroup" colspan="3">{{ $t('msg.NEWB010P110.004') }}</th> <!-- DET(예상) -->
              <th scope="colgroup" colspan="3">{{ $t('msg.NEWB010P110.005') }}</th> <!-- DEM(예상) -->
            </tr>
            <tr>
              <th scope="col">{{ $t('msg.NEWB010P110.006') }}</th> <!-- Size -->
              <th scope="col">{{ $t('msg.NEWB010P110.007') }}</th> <!-- Type -->
              <th scope="col">{{ $t('msg.NEWB010P110.008') }}</th> <!-- Q’ty -->
              <th scope="col">{{ $t('msg.NEWB010P110.009') }}</th> <!-- 픽업예정일 -->
              <th scope="col">{{ $t('msg.NEWB010P110.010') }}</th> <!-- 반입예정일 -->
              <th scope="col">{{ $t('msg.NEWB010P110.011') }}</th> <!-- 선적예정일 -->
              <th scope="col">{{ $t('msg.NEWB010P110.012') }}</th> <!-- Free Day -->
              <th scope="col">{{ $t('msg.NEWB010P110.013') }}</th> <!-- Over -->
              <th scope="col">{{ $t('msg.NEWB010P110.014') }}</th> <!-- 예상 금액 -->
              <th scope="col">{{ $t('msg.NEWB010P110.012') }}</th> <!-- Free Day -->
              <th scope="col">{{ $t('msg.NEWB010P110.013') }}</th> <!-- Over -->
              <th scope="col">{{ $t('msg.NEWB010P110.014') }}</th> <!-- 예상 금액 -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(container,index) in containerList" :key="index">
              <td>{{ container.cntrSzCd }}{{ container.cntrTypCd }}</td> <!--20GP -->
              <td>{{ container.cntrTypCd }}</td> <!-- GP -->
              <td>{{ container.cntrQty }}</td>
              <td>
                <!--                {{ container.pcupReqDt }}-->
                <e-date-picker v-model="container.pcupReqDt" @input="onInput(index)" :id="'pickupDate_' + index" />
              </td> <!-- 2012-11-11 -->
              <td>
                <e-date-picker v-model="container.expectDate" @input="onInput(index)" :id="'returnDate_' + index" />
              </td>
              <td>{{ etd }}</td> <!-- 선적예정일 -->
              <td>{{ container.detFreeTime }}<span v-show="container.detFreeTime || container.detFreeTime === 0">{{ $t('msg.NEWB010P110.016') }}</span></td> <!-- Freeday : n일 -->
              <td>{{ container.detOverDate }}<span v-show="container.detOverDate">{{ $t('msg.NEWB010P110.016') }}</span></td> <!-- Over -->
              <td>{{ container.expectDetRate }}</td>
              <td>{{ container.demFreeTime }}<span v-show="container.demFreeTime || container.demFreeTime === 0">{{ $t('msg.NEWB010P110.016') }}</span></td> <!-- Freeday: n일-->
              <td>{{ container.demOverDate }}<span v-show="container.demOverDate">{{ $t('msg.NEWB010P110.016') }}</span></td> <!-- Over -->
              <td>{{ container.expectDemRate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import demDetTariff from '@/api/rest/trans/demDetTariff'
import EDatePicker from '@/components/common/EDatePicker'

export default {
  name: 'DemDetCalculatorPop',
  components: {
    EDatePicker
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          containerList: [],
          etd: '',
          ctrCd: '',
          portCd: ''
        }
      }
    }
  },
  data () {
    return {
      etd: this.parentInfo.etd,
      containerList: []
    }
  },
  created () {
    console.log('created')
    this.containerList = this.parentInfo.containerList
    //FREEDAY 셋팅
    this.settingFreedays()
  },
  mounted: function () {
    console.log('mounted')
  },
  methods: {
    onInput (index) {
      if (this.returnDateValidationCheck(index)) {
        this.calculate(index)
      }
    },
    //유효성체크 - 반납예정일자가 픽업예정일보다 빠르거나, 선적예정일보다 느릴 경우
    returnDateValidationCheck (index) {
      let isOk = true

      const selector = document.querySelector('#returnDate_' + index)
      this.$ekmtcCommon.hideErrorTooltip(selector)

      let expectDate = new Date(selector.value).setHours(0, 0, 0, 0) //반입예정일
      let etdDate = new Date(this.parentInfo.etd).setHours(0, 0, 0, 0) //선적예정일

      //반납예정일이 선적예정일보다 느릴 경우
      if (expectDate > etdDate) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P110.017')) //반입예정일은 선적예정일보다 느릴 수 없습니다.
        isOk = false
      }

      //반납예정일이 픽업예정일보다 빠를 경우
      this.containerList.forEach((item) => {
        if (expectDate < new Date(item.pcupReqDt).setHours(0, 0, 0, 0)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P110.018')) //반입예정일은 픽업예정일보다 빠를 수 없습니다.
          isOk = false
        }
      })
      return isOk
    },
    //팝업 활성화시 Freeday값 미리 셋팅
    settingFreedays () {
      this.containerList.filter((item, index) => {
        //데이터 초기화
        this.init(item)

        let formData = {
          ctrCd: this.parentInfo.ctrCd,
          portCd: this.parentInfo.portCd,
          cntrTypCd: item.cntrTypCd,
          cgoTypCd: item.cgoTypCd
        }

        demDetTariff.getFreeDay(formData).then((data) => {
          console.log(data)
          data.data.map((item2) => {
            if (item2.demDetFlag === 'DEM') {
              item.demFreeTime = item2.freeDds
            } else if (item2.demDetFlag === 'DET') {
              item.detFreeTime = item2.freeDds
            } else if (item2.demDetFlag === 'COMBINED') {
              item.demFreeTime = ''
              item.detFreeTime = item2.freeDds
            }
          })
          //set for rerendering
          this.$set(this.containerList, index, item)
        })
      })
    },
    //팝업 실행시 데이터 초기화
    init (item) {
      item.demFreeTime = ''
      item.detFreeTime = ''
      item.expectDemRate = ''
      item.expectDetRate = ''
      item.detOverDate = ''
      item.demOverDate = ''
    },
    //계산 시작
    async calculate (index) {
      const formData = {
        ctrCd: this.parentInfo.ctrCd,
        portCd: this.parentInfo.portCd,
        eiCatCd: 'O',
        demDetCatCd: ''
      }

      // 부킹테스트용
      // const formData = {
      //   ctrCd: 'IN',
      //   portCd: 'HZR',
      //   eiCatCd: 'O',
      //   demDetCatCd: ''
      // }

      //계산할 컨테이너
      const targetContainer = this.containerList[index]

      let combinedDataList = null
      formData.demDetCatCd = '03'
      await demDetTariff.getDemDetTariffInfoList(formData).then((data) => {
        const result = data.data
        let detYn = 'N'

        if (result !== undefined) {
          combinedDataList = data.data.demDetCalculatorList

          //DET(COMBINED) 여부 체크
          if (combinedDataList !== undefined && combinedDataList.length > 0) {
            detYn = 'Y'
          }
        }

        //DET(COMBINED)가 없는 경우
        if (detYn === 'N') {
          //[DEM 계산]
          formData.demDetCatCd = '01'
          demDetTariff.getDemDetTariffInfoList(formData).then((data) => {
            if (data.data !== undefined) {
              const demDetDataList = data.data.demDetCalculatorList

              //계산할 컨테이너의 Type에 맞는 표 데이터 필터링
              const filteredData = this.filteredData(targetContainer, demDetDataList)

              if (!filteredData) {
                console.log('(DEM)해당 데이터가 없습니다')
              } else {
                //1. FREE타임 계산
                this.calculateFreeAndOverTime(targetContainer, filteredData, index)
                //2. 예상금액 계산
                //  Over일자가 없는 경우에는 초기화 처리
                if (targetContainer.demOverDate === 0) {
                  targetContainer.expectDemRate = 0
                } else {
                  this.calculateExpectFee(targetContainer, filteredData, index)
                }
              }
            }
          })
          //[DET 계산]
          formData.demDetCatCd = '02'
          demDetTariff.getDemDetTariffInfoList(formData).then((data) => {
            if (data.data !== undefined) {
              const demDetDataList = data.data.demDetCalculatorList

              //계산할 컨테이너의 Type에 맞는 표 데이터 필터링
              const filteredData = this.filteredData(targetContainer, demDetDataList)

              if (!filteredData) {
                console.log('(DET)해당 데이터가 없습니다')
              } else {
                //1. FREE타임 계산
                this.calculateFreeAndOverTime(targetContainer, filteredData, index)
                //2. 예상금액 계산
                //  Over일자가 없는 경우에는 초기화 처리
                if (targetContainer.detOverDate === 0) {
                  targetContainer.expectDetRate = 0
                } else {
                  this.calculateExpectFee(targetContainer, filteredData, index)
                }
              }
            }
          })
        } else {
          //[DET(COMBINED) 계산]

          //계산할 컨테이너의 Type에 맞는 표 데이터 필터링
          const filteredData = this.filteredData(targetContainer, combinedDataList)

          if (!filteredData) {
            console.log('(DET(COMBINED))해당 데이터가 없습니다')
          } else {
            //1. FREE타임 계산
            this.calculateFreeAndOverTime(targetContainer, filteredData, index)
            //2. 예상금액 계산
            //  Over일자가 없는 경우에는 초기화 처리
            if (targetContainer.detOverDate === 0) {
              targetContainer.expectDetRate = 0
            } else {
              this.calculateExpectFee(targetContainer, filteredData, index)
            }
          }
        }
      })
    },
    //데이터 필터링
    filteredData (targetContainer, list) {
      return list.filter((item) => {
        //특수화물 여부에 따른 필터링 조건
        return item.cntrTypCd === targetContainer.cntrTypCd && !item.cgoTypCd
      })[0]
    },
     //1. FREE타임 계산
    calculateFreeAndOverTime (targetContainer, filteredData, index) {
      const demDetFlag = filteredData.demDetTrfCatCd

      let freeTime = filteredData.freeDds
      if (!freeTime) {
        freeTime = 0
      }

      const formattedDate = targetContainer.expectDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
      const expectDate = new Date(formattedDate).setHours(0, 0, 0, 0) //반입예정일

      if (demDetFlag === '01') {
        const etdDate = new Date(this.parentInfo.etd).setHours(0, 0, 0, 0) //선적예정일
        targetContainer.demOverDate = this.calculateOverday(demDetFlag, expectDate, etdDate, freeTime)
      } else {
        const formattedPcupDate = targetContainer.pcupReqDt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        const pcupDate = new Date(formattedPcupDate).setHours(0, 0, 0, 0) //픽업예정일
        //const pcupDate = new Date(targetContainer.pcupReqDt).setHours(0, 0, 0, 0) //픽업예정일
        //console.log('expectDate:', expectDate, 'pcupDate:', pcupDate, '계산일:', this.calculateOverday(demDetFlag, expectDate, pcupDate, freeTime))
        targetContainer.detOverDate = this.calculateOverday(demDetFlag, expectDate, pcupDate, freeTime)
      }
      //set for rerendering
      this.$set(this.containerList, index, targetContainer)
    },
    //over일자 계산 (DEM/DET 플래그, 반입예정일, 픽업예정일 또는 선적예정일, DEM/DET 프리타임)
    calculateOverday (demDetFlag, returnDate, targetDate, freeTime) {
      let difference = ''
      let calculatedDay = ''
      if (demDetFlag === '01') {
        difference = targetDate - returnDate //DEM일자 계산(선적예정일 - 반입에정일)
        calculatedDay = (difference / (1000 * 3600 * 24)) + 1
      } else {
        difference = returnDate - targetDate //DET일자 계산(반입예정일 - 픽업예정일)
        calculatedDay = (difference / (1000 * 3600 * 24)) + 1
      }

      let result = (freeTime > calculatedDay) ? 0 : calculatedDay - freeTime //freeTime이 계산일자 보다 크면 0 셋팅

      if (isNaN(result)) {
        result = 0
      }

      return result
    },
    //2. 예상금액 계산
    calculateExpectFee (targetContainer, filteredData, index) {
      let total = 0
      let overDay = 0

      const qty = parseInt(targetContainer.cntrQty)
      const flag = filteredData.demDetTrfCatCd //01 : DEM, 02: DET, 03: DET(COMBINED)

      //FreeDay 포함여부에 따라 until DDS 변경
      const afterOrInclude = filteredData.rateApclOptCd
      const freeDay = parseInt(filteredData.freeDds)
      const until1 = (afterOrInclude === '01') ? parseInt(filteredData.trf1Dds) : ((parseInt(filteredData.trf1Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf1Dds) - freeDay)
      const until2 = (afterOrInclude === '01') ? parseInt(filteredData.trf2Dds) : ((parseInt(filteredData.trf2Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf2Dds) - freeDay)
      const until3 = (afterOrInclude === '01') ? parseInt(filteredData.trf3Dds) : ((parseInt(filteredData.trf3Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf3Dds) - freeDay)
      const until4 = (afterOrInclude === '01') ? parseInt(filteredData.trf4Dds) : ((parseInt(filteredData.trf4Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf4Dds) - freeDay)

      if (flag === '01') {
        overDay = parseInt(targetContainer.demOverDate)
      } else {
        overDay = parseInt(targetContainer.detOverDate)
      }

      filteredData.size = targetContainer.cntrSzCd
      filteredData.overDay = overDay

      if (overDay === 0) {
        return
      }

      console.log(filteredData)

      //MEMO :: until dds 데이터 기준이 정확하지 않고 무한 표기를 999,9999,10000,0 등으로 해놓았기 때문에 includeFreeday를 해도 영향 없도록 약 900일로 설정

      //TARIFF 1 계산
      if (until1 === 0 || until1 >= 900) {
        let totalData = this.calculateOverRate(filteredData, 1, 'Y')
        total += totalData.total
      } else {
        let totalData = this.calculateOverRate(filteredData, 1, 'N')
        total += totalData.total

        if (totalData.overFlag === false) {
          //TARRIFF 2 계산
          if (until2 === 0 || until2 >= 900) {
            let totalData = this.calculateOverRate(filteredData, 2, 'Y')
            total += totalData.total
          } else {
            let totalData = this.calculateOverRate(filteredData, 2, 'N')
            total += totalData.total

            if (totalData.overFlag === false) {
              //TARRIFF 3 계산
              if (until3 === 0 || until3 >= 900) {
                let totalData = this.calculateOverRate(filteredData, 3, 'Y')
                total += totalData.total
              } else {
                let totalData = this.calculateOverRate(filteredData, 3, 'N')
                total += totalData.total

                if (totalData.overFlag === false) {
                  //TARRIFF 4 계산
                  if (until4 === 0 || until4 >= 900) {
                    let totalData = this.calculateOverRate(filteredData, 4, 'Y')
                    total += totalData.total
                  } else {
                    let totalData = this.calculateOverRate(filteredData, 4, 'N')
                    total += totalData.total
                  }
                }
              }
            }
          }
        }
      }

      const calculatedResult = this.$ekmtcCommon.changeNumberFormat(String(qty * total), { isComma: true }) + '(' + filteredData.curCd + ')'

      if (flag === '01') {
        targetContainer.expectDemRate = calculatedResult
      } else {
        targetContainer.expectDetRate = calculatedResult
      }

      //set for rerendering
      this.$set(this.containerList, index, targetContainer)
    },
    //예상금액 계산하기
    calculateOverRate (filteredData, tariffNo, infinite) {
      let total = 0
      let start = 0
      let untilDate = 0
      let rate20 = 0
      let rate40 = 0
      let rate45 = 0
      let overFlag = false

      //until Date 셋팅
      //MEMO :: until dds 데이터 기준이 정확하지 않고 무한 표기를 999,9999,10000,0 등으로 해놓았기 때문에 includeFreeday를 해도 영향 없도록 약 900일로 설정
      const afterOrInclude = filteredData.rateApclOptCd
      const freeDay = parseInt(filteredData.freeDds)

      //After Freeday 또는 Include Freeday 여부에 따른 until date 계산
      const resultUntilDate1 = (afterOrInclude === '01') ? parseInt(filteredData.trf1Dds) : ((parseInt(filteredData.trf1Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf1Dds) - freeDay)
      const resultUntilDate2 = (afterOrInclude === '01') ? parseInt(filteredData.trf2Dds) : ((parseInt(filteredData.trf2Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf2Dds) - freeDay)
      const resultUntilDate3 = (afterOrInclude === '01') ? parseInt(filteredData.trf3Dds) : ((parseInt(filteredData.trf3Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf3Dds) - freeDay)
      const resultUntilDate4 = (afterOrInclude === '01') ? parseInt(filteredData.trf4Dds) : ((parseInt(filteredData.trf4Dds) - freeDay <= 0) ? 0 : parseInt(filteredData.trf4Dds) - freeDay)

      //TARIFF별로 기간 및 적용 비용 셋팅
      if (tariffNo === 1) {
        console.log('tariff1')
        untilDate = resultUntilDate1
        start = 1
        rate20 = parseInt(filteredData.trf1Rate20)
        rate40 = parseInt(filteredData.trf1Rate40)
        rate45 = parseInt(filteredData.trf1Rate45)
      } else if (tariffNo === 2) {
        console.log('tariff2')
        untilDate = resultUntilDate2
        start = 1 + resultUntilDate1
        rate20 = parseInt(filteredData.trf2Rate20)
        rate40 = parseInt(filteredData.trf2Rate40)
        rate45 = parseInt(filteredData.trf2Rate45)
      } else if (tariffNo === 3) {
        console.log('tariff3')
        untilDate = resultUntilDate3
        start = 1 + resultUntilDate2
        rate20 = parseInt(filteredData.trf3Rate20)
        rate40 = parseInt(filteredData.trf3Rate40)
        rate45 = parseInt(filteredData.trf3Rate45)
      } else {
        console.log('tariff4')
        untilDate = resultUntilDate4
        start = 1 + resultUntilDate3
        rate20 = parseInt(filteredData.trf4Rate20)
        rate40 = parseInt(filteredData.trf4Rate40)
        rate45 = parseInt(filteredData.trf4Rate45)
      }

      const size = filteredData.size
      const overDay = filteredData.overDay

      let forValue = (infinite === 'Y') ? 9999 : untilDate

      console.log('start:', start)
      console.log('forValue:', forValue)
      console.log('size:', size)
      console.log('target20:', rate20)
      console.log('target40:', rate40)
      console.log('target45:', rate45)

      //계산시작
      for (let i = start; i <= forValue; i++) {
        if (size === '20') {
          total += rate20
        } else if (size === '40') {
          total += rate40
        } else if (size === '45') {
          total += rate45
        }
        //OVER일수 넘으면 계산 종료
        if (i === overDay) {
          overFlag = true
          break
        }
      }

      console.log(total)

      return { total: total, overFlag: overFlag }
    }
  }
}
</script>

<style scoped>
</style>
